import React, {PureComponent} from 'react'
import logo from '../logo.svg'

export default class Index extends PureComponent {
    render () {
        return (
            <div className='App'>
                <img src={logo} className="" alt='logo' />
                Coming soon!
            </div>
        )
    }
}